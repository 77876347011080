<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Workflow" icon="sitemap" />

    <div class="mainBoxBorderless mainBox55">
        <div class="text-end">
            <!-- <span class="text-primary isLink" @click="$router.push({ path: '/workflow/add' })"> -->
            <span class="text-primary isLink me-3" data-bs-toggle="modal" data-bs-target="#mdlAddItem">
                <fa icon="sitemap" />
                <span class="fw-bold me-2" style="font-size: 14px">+</span>Add workflow
            </span>
            <span class="text-primary isLink" @click="$router.push({ path: '/workflow/version/list' })">
                <fa icon="code-branch" class="me-2" />Workflow Document
            </span>
        </div>
    </div>

    <div class="nextBox nextBox55 shadow-sm border">
        <div class="row">
            <div class="col-12 px-1" ref="scrollComponent">
                <div v-if="isLoading" class="mb-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>
                <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
 
                <table class="table table-hover" border="0">
                    <tbody>

                        <tr v-for="(item, itemIdx) in arrItem" :key="item">
                            <td>
                                <div class="row">
                                    <div class="col-lg-6 fw-bold isLink" @click="$router.push({ path: '/workflow/add' })">
                                        {{item.title}}
                                    </div>
                                    <div class="col-lg-2 text-grey small"><span class="badge bg-grey">{{item.totalStep}}</span> steps</div>
                                    <div class="col-lg-3">
                                        <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(item.dateModify, 'datetime')">
                                            <span class="isTooltipsLabel text-grey small fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateModify), new Date()) }}</span>
                                        </Popper>
                                    </div>
                                    <div class="col-lg-1 text-end d-none d-md-block hoverShow">
                                        <span class="text-grey isLink" @click="delItem(itemIdx)"><fa icon="trash" size="lg me-2" /></span>
                                    </div>

                                    <div class="col-12 text-end d-md-none">
                                        <span class="text-grey isLink" @click="delItem(itemIdx)"><fa icon="trash" size="lg me-2" /></span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr v-if="isLoadingMore" class="mt-3 text-center">
                            <td>
                                <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddItem"
        tabindex="-1"
        aria-labelledby="AddItem"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add Workflow</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <input type="text" v-model="title" class="form-control" placeholder="Title" />
                        </div>
                    </div>
                </div>
                <div v-if="role !== null || signee !== null" class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button v-if="title !== ''" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="addItem()">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'

export default {
    name: 'WorkFlow',
    components: {
        TopNavigation, Alert
    },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const title = ref('')
        const arrItem = ref([])
        const itemNo = ref(0)

        const scrollComponent = ref(null)
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const addItem = () => {
            // arrItem.value[itemNo.value] = { id: itemNo.value, title: title.value }
            arrItem.value.push({ id: itemNo.value, title: title.value })
            itemNo.value ++
            title.value = null
        }

        const delItem = (idx) => {
            arrItem.value.splice(idx, 1)
        }

        const getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    id: 1001,
                    title: 'WorkFlow Testing Number One',
                    dateModify: '2022-09-20T09:09:12.000Z',
                    dateCreate: '2022-09-20T07:36:33.000Z',
                    totalStep: 10,
                },
                {
                    id: 1002, 
                    title: 'Deployment Flow',
                    dateModify: '2022-09-20T05:12:00.000Z',
                    dateCreate: '2022-09-20T05:10:30.000Z',
                    totalStep: 5,
                },
                {
                    id: 1003, 
                    title: 'Change Request Flow',
                    dateModify: '2022-09-18T09:25:20.000Z',
                    dateCreate: '2022-09-14T07:00:19.000Z',
                    totalStep: 12,
                },
            ]
        }

        const getTotal = () => {
            total.value = 2
        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            rcdFr.value = 0

            getTotal()
            getList(false)
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return { 
            route, router, alert, closeAlert, func, scrollComponent, 
            total, rcdFr, rcdDisplay, isLoading, isLoadingMore, arrItem, getTotal, loadMore, 
            itemNo, addItem, delItem, title, 
        }

    }
}
</script>

<style>
</style>